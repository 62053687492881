.clients div:first-child {
    padding: 0 24px 0 24px;
}

.clients_logo {
    padding: 30px auto 10px auto;
    justify-content: center;
    align-items: center;
    gap: 32px 24px;
    overflow-x: scroll;
    scrollbar-width: none;
    flex-wrap: wrap;
}

.rcs_client_title {
    font-family: "Nunito Sans";
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: 0.5%;
    color: #003366 ;
    transition: all 0.3 ease;
}
.clients_img_container {
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 66px;
}

.clients_img_container img {
    object-fit: contain !important;
}

@media screen and (max-width: 373px) {
    .clients_logo {
        gap: 32px 0px;
    }
}

.clients_logo::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
}

.clients_img_container {
    width: 160px;
}

.clients_logo img {
    height: fit-content;
    height: 60px !important;
    max-height: 71px;
    max-width: 120px;
    overflow-anchor: none !important;
}

.clients_logo>img:first-child {
    max-width: 100px;
}
@media screen and (max-width: 322px) {
    .clients_logo {
        gap: 12px;
    }
    .clients_logo img {
        margin-top: 20px;
        max-height: 60px;
        max-width: 90px;
    }
    .clients_logo>img:first-child {
        max-width: 80px;
    }
}


/** mini tab styles */

@media screen and (max-width: 768px) {
    .clients_logo {
        display: flex;
        flex-direction: row;
        padding: 24px 0 0 0 !important;
    }
    .clients_logo img {
        padding: 0 !important;
        height: 50px !important;
    }
}

@media screen and (min-width: 768px) {
    .clients_logo {
        gap: 48px 30px;
        padding: 24px 0 0 0 !important;
    }
}

@media screen and (max-width: 992px) { 
    .rcs_client_title {
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
    }
}

/** tab styles */

@media screen and (min-width: 992px) {
    .clients_logo {
        /* justify-content: flex-start; */
         justify-content: space-between; 
        // justify-content: center;
        width: 100%;
        padding: 40px 0px 50px 0px;
    }
    .clients_logo img {
        height: 60px !important;
        max-height: 118px;
        max-width: 298px;
    }
    .clients_img_container {
        width: 240px;
    }
}