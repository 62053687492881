@use 'fonts';

.learn_more {
  // @include fonts.mhu-paragraph;
  text-decoration: underline;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #003366 ;
  transition: all 0.3 ease;

  > img {
    position: relative;
    top: 4px;
    margin-left: 14px;
    width: 10px;
    height: 16px;
  }
}


/** small pc / tab styles */
@media screen and (min-width: 992px){
  .learn_more {
    // @include fonts.hu-header-5;
    width: 12px;
    height: 20px;
  }
}