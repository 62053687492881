.request_section {
    background-color: var(--primary);
    width: 100%;
    height: 236px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.request_section>div {
    text-align: center;
    justify-content: center;
}

.request_section_title {
    display: none;
    font-family: "Nunito Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--secondary);
}

.request_section_subtitle {
    padding: 0px 16px;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #FFFFFF;
    >span {
        color: var(--secondary);
    }
}


.request_section_btn {
    display: flex; 
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
    margin: 24px auto 0 auto;
    min-width: 156px;
}

.request_section_btn.web {
    display: none;
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) {
        /* Safari Only CSS here */
        .request_section {
            height: 254px;
        }
        .request_section_btn {
            min-width: 164px !important;
        }
    }
}

@media screen and (max-width: 322px) {
    .request_section {
        padding-top: 28px;
        height: 268px;
    }
}


/** mini tab styles */

@media screen and (min-width: 768px) {}


/** tab styles */

@media screen and (min-width: 992px) {
    .request_section {
        height: 250px;
    }
    .request_section_title {
        display: block;
    }
    .request_section_subtitle {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
        max-width: 613px;
        /* max-width: 718px; */
        margin: 16px auto 0 auto;
    }
    .request_section_btn.mobile {
        display: none;
    }
    .request_section_btn.web {
        display: block;
    }
    .request_section_btn {
        font-size: 18px;
        line-height: 32px;
        color: var(--primary);
        margin: 16px auto 0 auto;
        min-width: 210px;
        font-weight: 600;
    }
    @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance: none) {
            /* Safari Only CSS here */
            .request_section_btn {
                min-width: 220px !important;
            }
        }
    }
    @-moz-document url-prefix() {
        .request_section_btn {
            /** firefox only */
            padding-top: 10px;
            min-width: 220px !important;
        }
    }
}


/** desktop styles */

@media screen and (min-width: 1200px) {
    .request_section {
        height: 254px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .request_section_subtitle {
        font-size: 24px;
        line-height: 32px;
        max-width: 613px;
        margin-top: 16px;
        margin-bottom: 34px;
    }
    .request_section_btn {
        font-size: 18px;
        line-height: 32px;
        color: var(--primary);
        margin: 16px auto 0 auto;
    }
}