@use 'fonts';

.rcs_video {
  justify-content: center;
  text-align: center;
  padding: 39px 17px 28px 17px;
}

.rcs_title {
  font-family: "Nunito Sans";
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: 0.5%;
  color: #003366 ;
  transition: all 0.3 ease;
}

.rcs_desc {
  margin-top: 7.7px;
  font-size: 16px;
  line-height: 26px;
  // font-weight: 500;
  letter-spacing: 0.25%;
  color: #2B4156;
  text-align: center;
  transition: all 0.3 ease;
}

.rcs_video > .request_section_btn {
  margin: 16px auto 16px auto;
  font-size: 1.5em;
  width: 171px;
  height: 40px;
}

@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance: none) {
    /* Safari Only CSS here */
    .rcs_video > .request_section_btn {
      width: 184px !important;
    }
  }
}

.rcs_show_more {
  line-height: 24px;
  /* font-size: 24px; */
  /* line-height: 32px; */
  letter-spacing: 0em;
  color: #003366;
  text-decoration-line: underline;
}

.rcs_show_more span {
  display: inline;
  font-size: 1.167em;
}

.rcs_show_more img {
  width: 10px;
  height: 16.67px;
  display: none;
  margin: auto 0 auto 16px;
  height: 20px;
  width: 12px;
  border-radius: 0px;
  padding-top: 4px;
}

.rcs_youtube {
  margin-top: 16px;
  /* display: flex;
  gap: 0px; */
}

/* .rcs_youtube > span {
  position: relative;
  top: 3px;
  right: 276px;
  height: 44px;
  width: 200px;
  background-color: black;
} */

.rcs_youtube > iframe {
  width: 300px;
  height: 170px;
  /* position: relative;
  left: 21px; */
}

@media screen and (max-width: 322px){
  .rcs_youtube > iframe {
    width: 280px;
    height: 160px;
  }
}

/** small phones styles */
@media screen and (min-width: 400px){
  .rcs_video {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) { 
  .rcs_title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }
  .rcs_desc {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin: 24px auto;
  }
}


/** small pc / tab styles */
@media screen and (min-width: 992px){
  .rcs_video {
    font-size: 16px;
  }
  .rcs_video {
    padding: 60px 17px 30px 17px;
  }
  
  .rcs_desc {
    // font-size: 16px;
    // line-height: 24px;
    // max-width: 900px;
    margin: 24px auto;
  }
  
  .rcs_video > .request_section_btn {
    margin: 24px auto 24px auto;
    font-size: 1em;
    width: 174px;
    height: 52px;
  }
  
  .rcs_show_more span {
    display: inline;
    font-size: 1.25em;
    line-height: 32px;
    font-weight: 400;
  }
  
  .rcs_show_more img {
    width: 12px;
    height: 20px;
    display: inline-block;
    margin: auto 0 auto 15px;
    border-radius: 0px;
    padding-top: 4px;
  }
  
  .rcs_youtube {
    margin-top: 24px;
    /* display: flex;
    gap: 0px; */
  }
  
  /* .rcs_youtube > span {
    position: relative;
    top: 3px;
    right: 276px;
    height: 44px;
    width: 200px;
    background-color: black;
  } */
  
  .rcs_youtube > iframe {
    width: 761px;
    height: 432px;
    /* position: relative;
    left: 21px; */
  }
}